.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.container{
  background:orange;
}
.container_full{
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  /*
  background:orange;
  */
}
.container_full_fixed{
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: -1;
  /*
  background:orange;
  */
}
.container_full_percent{
  /*background:#09d3ac;*/
  width:100%;
  height:100%;
}

div.container_menu{
  position:fixed;
  z-index:1;
  left:23px;
  top:77px;
  width:200px;
  height:400px;
  background:white;
  display:flex;
  /*justify-content: space-between;*/
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;

  border-radius: 100px;


  background: #BFF;
	background: linear-gradient(top, #BFF 5%, #DFF 100%);
	background: -webkit-linear-gradient(top, #FFF 1%, #BFF 100%);
	background: -moz-linear-gradient(top, #BFF 5%, #DFF 100%);
	background: -ms-linear-gradient(top, #BFF 5%, #DFF 100%);
  background: -o-linear-gradient(top, #BFF 5%, #DFF 100%);
  
  
}
div.container_menu:before {
	width: 200px; height: 200px;
	top: -90px; right: 50px;
	border-radius: 200px;
}
div.container_menu:after, #cloud:before {
	content: '';
	position: absolute;
	background: #FFF;
	z-index: -1
}
div.container_menu > div{
  font-size:20px;
  text-align:center;
  margin:20px 0;
}
.desarrollado{
  position:fixed; bottom:50px; left:0; right:0;
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items:center;
}


.tiposervicio{
  width:30%;
  
}
.tiposerviciolink{
  text-decoration:none;
  color:#003480;
}
.contenedor_menu_btn{
  position:fixed;
  left:30px;
  top:10px;
}
.contenedor_titulo_seccion{
  position:fixed;
  left:80px;
  top:3px;
  color:#003480;
  /*font-weight:bold;*/
  font-size:30px;
  text-transform: uppercase;
}
.logo_home{
  width:50%;
  margin-top:50px;
}

@media screen and (max-width:440px) {
  .tiposervicio{
    width:100%;
    
  }  
  .tiposerviciolink{
    text-decoration:none;
    color:#003480;
  }
}
@media screen and (min-width:441px) and (max-width:999px) {

}

