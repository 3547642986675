body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.display-linebreak {
  white-space: pre-line;
}


                    body {
                    background: #333;
                    padding: 0;
                    margin: 0;
                    }

                    .wrapper {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    /*
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    */
                    border-radius: 5px;
                    background-image: linear-gradient(to top, #accbee 0%, #e7f0fd 100%);
                    overflow: hidden;
                    }

                    .wave {
                    width: 1000px;
                    height: 1025px;
                    position: absolute;
                    /*
                    top: 0;
                    */
                    top: 10%;
                    left: 50%;
                    
                    /*
                    top: -25%;
                    left: 50%;
                    */
                    margin-left: -500px;
                    margin-top: -500px;
                    border-radius: 35%;
                    background: rgba(255, 255, 255, .75);
                    animation: wave 15s infinite linear;
                    }

                    @keyframes wave {
                    from { transform: rotate(0deg);}
                    from { transform: rotate(360deg);}
                    }

                    